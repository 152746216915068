import Glide, { Autoplay, Controls } from '@glidejs/glide/dist/glide.modular.esm'

// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

const gap= 70;
const columnWidth = 250;
const resizeDelay = 200;
const margin = 50;

document.addEventListener("DOMContentLoaded", function() {
    const glideEl = document.querySelector('.glide');
    if (glideEl) {
        const glide = new Glide(glideEl, {
            type: 'slider',
            gap: 0,
            autoplay: 5000,
            animationDuration: 1000
        });
        glide.mount({ Autoplay, Controls });
    }

    const imagesElement = document.querySelector('.images');

    if (!imagesElement) {
        return;
    }

    let triggered = false;
    let prevColumns = -1;
    let dialogShown = false;
    let dialogOrigin = { x: null, y: null };
    let dialogTriggered = false;
    let scroll = { x: null, y: null };

    const imageElements = document.querySelectorAll('.image-wrapper');
    const body = document.getElementsByTagName('body')[0];
    const rootElement = document.querySelector('.root');
    const dialogElement = document.querySelector('.image-dialog');
    const wrapperDialogElement = document.querySelector('.image-dialog__wrapper');
    const ghostDialogElement = document.querySelector('.image-dialog__ghost');
    const innerDialogElement = document.querySelector('.image-dialog__inner');
    const dialogCloseElement = document.querySelector('.image-dialog__close');
    const dialogImageElement = document.querySelector('.image-dialog__image');
    const dialogTitleElement = document.querySelector('.image-dialog__title');

    function layout() {
        const availableWidth = body.offsetWidth - margin * 2;
        // c < (max - g(w-1)) / w
        let columns = Math.floor((availableWidth + gap) / (columnWidth + gap));

        if (columns !== prevColumns) {

            if (columns === 0) {
                columns = 1;
            }
            const realWidth = columns * columnWidth + (columns - 1) * gap;
            console.log(columns);

            imagesElement.style.columnCount = columns;
            imagesElement.style.width = realWidth + 'px';
            imagesElement.style.columnGap = gap + 'px';

            prevColumns = columns;
        }

        triggered = false;
    }

    function handleLockedScroll() {
        window.scrollTo(scroll.x, scroll.y);
    }

    function setDialogShown(shown) {
        if (shown === dialogShown) return;
        dialogShown = shown;

        dialogElement.classList.toggle('image-dialog--shown', dialogShown);

        if (shown) {
            scroll.x = window.scrollX;
            scroll.y = window.scrollY;
            window.addEventListener('scroll', handleLockedScroll);
        } else {
            ghostDialogElement.classList.toggle('zero', false);
            window.removeEventListener('scroll', handleLockedScroll);
        }
    }

    function toggleDialog() {
        setDialogShown(!dialogShown);
    }

    function handleImageClick(e) {
        if (dialogTriggered) return;

        dialogTriggered = true;

        const wrapper = e.target.closest('.image-wrapper');
        wrapper.classList.toggle('image-wrapper--loading', true);
        dialogTitleElement.innerText = wrapper.querySelector('.image-label').innerText;

        const image = new Image();
        const src = wrapper.querySelector('.image').getAttribute('data-url');

        dialogImageElement.onload = function() {
            wrapperDialogElement.scrollTop = 0;
            dialogElement.scrollTop = 0;
            innerDialogElement.scrollTop = 0;
            dialogImageElement.scrollTop = 0;
            ghostDialogElement.scrollTop = 0;

            dialogOrigin.x = window.innerWidth <= 600 ? 0 : e.clientX - (window.innerWidth / 2);
            dialogOrigin.y = window.innerWidth <= 600 ? window.innerHeight + 100 : e.clientY - (window.innerHeight / 2);

            ghostDialogElement.classList.toggle('zero', true);
            ghostDialogElement.classList.toggle('transitioning', false);
            dialogElement.classList.toggle('transitioning', false);

            dialogElement.style.zIndex = -1;
            dialogElement.style.opacity = '0.01';
            dialogElement.style.visibility = 'visible';
            ghostDialogElement.style.visibility = 'visible';
            ghostDialogElement.style.transform = 'scale(1)';

            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    dialogElement.style.zIndex = null;
                    dialogElement.style.opacity = null;
                    dialogElement.style.visibility = null;
                    ghostDialogElement.style.visibility = null;
                    ghostDialogElement.style.transform = null;

                    const scale = window.innerWidth <= 600 ? 1 : 0.1;
                    ghostDialogElement.style.transform = `translate(${dialogOrigin.x}px, ${dialogOrigin.y}px) scale(${scale}) translateZ(0)`;
                    ghostDialogElement.classList.toggle('zero', false);

                    requestAnimationFrame(() => {
                        requestAnimationFrame(() => {
                            ghostDialogElement.classList.toggle('transitioning', true);
                            dialogElement.classList.toggle('transitioning', true);
                            ghostDialogElement.classList.toggle('zero', true);
                            toggleDialog();
                            wrapper.classList.toggle('image-wrapper--loading', false);
                            dialogTriggered = false;
                        });
                    });
                });
            });

        }
        dialogImageElement.src = src;
    }

    imagesElement.style.margin = '0 auto';
    imagesElement.style.marginTop = gap + 'px';
    imagesElement.style.marginBottom = gap + 'px';
    imageElements.forEach(e => {
        e.style.paddingBottom = gap + 'px';
        e.style.width = columnWidth + 'px';

        e.addEventListener('click', handleImageClick);
    });
    dialogElement.addEventListener('click', (e) => {
        if (!innerDialogElement.contains(e.target) && !dialogTriggered) {
            toggleDialog();
        }
    });
    dialogCloseElement.addEventListener('click', toggleDialog);
    layout();

    window.addEventListener('resize', function(event) {
        if (!triggered) {
            triggered = true;
            setTimeout(layout, resizeDelay);
        }
    }, true);
});